import UDFRadio from "features/clients/details/userDefinedFields/components/Radio";
import UDFCheckbox from "features/clients/details/userDefinedFields/components/checkbox";
import UDFDatePicker from "features/clients/details/userDefinedFields/components/datePicker";
import UDFInput from "features/clients/details/userDefinedFields/components/input";
import UDFOptions from "features/clients/details/userDefinedFields/components/options";
import UDFSelect from "features/clients/details/userDefinedFields/components/select";
import UDFTextArea from "features/clients/details/userDefinedFields/components/textArea";
import UDFTimePicker from "features/clients/details/userDefinedFields/components/timePicker";

const K = {
  NullPlaceholder: "N/A",
  Server: process.env.REACT_APP_SERVER,
  Environment: {
    DEV: "DEV",
    QA: "QA",
    STAGING: "STAGING",
  },
  Network: {
    URL: {
      Base: process.env.REACT_APP_BASE_URL,
      BaseAPI: process.env.REACT_APP_BASE_API_URL,
      DomainName: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
      Timeout: process.env.REACT_APP_TIMEOUT,
      Protocol: process.env.REACT_APP_PROTOCOL,
      Client: {
        BaseHost: process.env.REACT_APP_CLIENT_BASE_HOST,
        BasePort: process.env.REACT_APP_CLIENT_BASE_PORT,
      },
      Auth: {
        Login: "/user/login",
        ForgotPassword: "/user/send_password_reset_token",
        ResetPassword: "/user/reset_password",
        LoggedInUserDetails: "/get-logged-in-user-details",
        ChangePassword: "/change-user-password-by-settings",
      },
      Lookup: {
        Sources: "/lookup/sources",
        AddSources: "/lookup/adsources",
        ReportingSchedule: "/lookup/reporting-schedules",
        Stage: "/lookup/stages",
        AtsNames: "/lookup/ats",
        CandidateStatus: "/lookup/candidate-status",
        JobStatus: "/lookup/job-status",
        JobLocations: "/lookup/job-locations",
        JobDepartments: "/lookup/job-departments",
        JobGroups: "/lookup/job-groups",
        JobTitle: "/lookup/job-titles",
        ContactTypes: "/lookup/contact-types",
        UserDesignation: "/lookup/user-designations",
        Ownership: "/lookup/ownership",
        BillingTypes: "/lookup/one-time-payment-billing-type",
        SaveSorting: "/order-table-rows",
        GetHistory: "/look-up-history",
        ClassStatus: "/lookup/class-status",
      },
      Clients: {
        GetAll: "/clients",
        GetAllName: "/client/get-all-client-name",
        GetDetails: "/client-detail",
        Create: "/client/store",
        BillingRuleTest: "/client/billing-rule-test",
        Recruiters: "/clients-recruiters",
        ClientDetails: {
          Overview: "/client-detail",
          Jobs: "/client/jobs",
          JobsWithServerSide: "/client/jobs-server-side",
          JobBillingRules: "/tiers/get-client-tiers",
          CreateJob: "/job-source/create",
          ClientBillingRules: "/client-billing-rules-entries",
          ClientLevelBillingRules: "/client-level-billing-rules-edit",
          JobLevelBillingRules: "/job-level-billing-rules-edit",
          EditExistingJobLevelBillingRuleDate:
            "/edit-existing-billing-rule-job-date",
          AddTier: "/job-level-billing-rules-edit",
          Client: "/client",
          JobTier: "/job-tier",
          BillingUnitsEdit: "/billing-units-edit",
          AddBillingUnit: "/add-individual-billing-unit",
          ClientInformationEdit: "/client-detail-edit",
          UpdateTierDetails: "/tier/update",
          EditJob: "/job-source/update",
          AbortClientBillingRule: "/client/client-billing-rule/abort",
          AbortJobBillingRule: "/client/job-billing-rule/abort",
          EditUpcomingClientRule:
            "/client/upcoming-client-level-billing-rules-edit",
          EditUpcomingJobRule: "/client/upcoming-job-level-billing-rules-edit",
          EditClientBillingRuleStartDate: "/edit-existing-billing-rule-date",
        },
      },
      ClientLookupTable: {
        JobLocationByMultipleClient: "/lookup/job-location-by-multiple-clients",
        JobDepartmentByMultipleClient:
          "/lookup/job-department-by-multiple-clients",
      },
      Jobs: {
        GetByClientIds: "/jobs-by-client-ids",
        GetByClientIdsWithServerSide: "/jobs-by-client-ids-with-server-side",
        GetById: "/job-source/get-job-detail-by-id",
        UpdateVacancies: "/job-source/update-vacancies",
        GetSettings: "/job-source/get-job-setting-by-id",
        UpdateSettings: "/job-source/update-job-settings",
        CancelById: "/job-source/cancel-job-by-id",
        GetStatistics: "/job/statistics",
        UpdateJobStatus: "/job-source/update-job-status-by-id",
        DeleteJob: "/delete-conditional-job-source",
        CustomCancelBilling: "/job-source/custom-cancellation-billing",
        GetOnlyJobsByClientIds: "/client-jobs-for-candidate-job-change",
        FilledJobLastOpening: "/job-candidates-to-decline",
      },
      Invoices: {
        GetAll: "/invoice-list",
        GetDetails: "/invoice-detail",
        Update: "/invoice-update",
        EditInvoice: "/start-date-invoice",
        DeleteInvoice: "/conditional-delete-invoice",
        InvoiceDetailExport: "/export/invoice-detail",
        BulkUpdate: "/invoice/bulk-status-update",
      },
      BillingSchedule: {
        GetJobBillingSchedule: "/scheduled-job-billing/get-by-clients-id",
        GetClientBillingSchedule: "/scheduled-client-billing/get-by-clients-id",
        UpdateJobScheduledbilling: "/scheduled-job-billing/update",
        UpdateClientScheduledbilling: "/scheduled-client-billing/update",
        GetScheduledBillingByCandidateId:
          "/scheduled-job-billing/get-by-candidate-id",
      },
      BillingApproval: {
        GetClientSubmittedBilling: "/get-client-submitted-billing",
        GetSubmittedBillHistory: "/get-submitted-bill-history",
        EditJobSubmittedBilling: "/edit-job-submitted-billing",
        EditClientSubmittedBilling: "/edit-client-submitted-billing",
        CurrencyConversion: "/currency-conversion",
        CreateInvoice: "/submitted-billing-invoice",
        IssueCredit: "/job-issue-credit",
        IssueCreditReason: "/issue-credit-reason",
        ReCalculateBillings: "/status/re-calculate-billings",
        RunTimeCalculations: "/status/run-time-billing-calulcations",
        SubmitCalculateDiscount: "/volume-bases/validate-discount",
      },
      BillingClient: {
        GetAll: "/client-level-billing-type",
        Create: "/client-level-billing-type/store",
        AddNew: "/client-level-billing-rules-edit",
        GetOneTimeEntries:
          "/submit-to-one-time-billing/get-onetime-entries-by-client-id",
        SubmitOneTimeBilling: "/submit-to-one-time-billing/submit",
        EditBillingClient: "/client-level-billing-type/update",
      },
      BillingJob: {
        GetAll: "/job-billing-type",
        Create: "/job-billing-type/store",
        EditBillingJob: "/job-billing-type/update",
        SubmitOnetime: "/candidate-activity-submit-one-time-billing",
      },
      VBD: {
        create: "/volume-bases/create",
        GetByClientId: "/volume-bases/clients-details",
      },
      Users: {
        GetAll: "/user/get-all",
        GetByRoleId: "/user/get-all-users-by-role-id",
        GetByDesignationId: "/user/get-user-by-designation_id",
        CreateUser: "/user/create-user",
        UpdateUser: "/user/update-user",
        UserDetailById: "/user-detail",
        EditUserProfile: "/update-user-by-setting",
        GetLogs: "/logs/get-general-activity",
        SaveColumnSort: "/config/columns-sorting-by-user",
        GetUserPlanTimeline: "/user-plan-timeline",
        GetConfigrations: "/config/get-user-configuration",
        HandleUserActive: "/user/handle-activaton-user",
        GetClientHiringManager: "/user/get-hiring-managers-by-client-id",
        DeleteUser: "/delete-conditional-user",
      },
      ClientContacts: "/contacts",
      DeleteClientContact: "/delete-conditional-contact",
      ClientDeparments: "/client-departments",
      Currency: "/currencies",
      Tiers: {
        GetClientTiers: "/tiers/get-client-tiers",
      },
      V2: {
        Dashboard: {
          Pipeline: {
            Summary: "/v2/dashboard/pipeline/summary",
            Details: "/v2/dashboard/pipeline/details",
          },
        },
        Candidates: {
          All: "/v2/candidates",
          Activities: "/v2/candidates/:id/activities",
          Merge: "/v2/candidates/merge",
          Edit: "/v2/jobs/:jobId/candidates/:candidateId",
        },
        Export: {
          Reports: {
            JobsVacancies: "/v2/export/reports/jobs-vacancies",
            CandidateJobEntries: "/v2/export/reports/candidate-job-entries",
            Engagements: "/v2/export/reports/engagements",
          },
        },
        Engagements: {
          All: "/v2/engagements",
          Edit: "/v2/engagements/:id",
          Recruiters: "/v2/engagements/recruiters",
        },
      },
      Candidate: {
        Get: "/candidate",
        GetAll: "/candidate/get-all",
        GetActivity: "/candidate-entries-recrutor-comments",
        GetById: "/candidate/get-candidate-detail-by-id",
        AddToBlacklist: "/candidate/add-candidate-to-blacklist",
        RemoveToBlacklist: "/candidate/remove-candidate-to-blacklist",
        Create: "/candidate/create",
        Edit: "/candidate/edit",
        ChangeStatus: "/status/change-candidate-status",
        AddComment: "/comment/updateOrCreate",
        GetComments: "/comment/candidate-comments",
        GetLogs: "/logs/candidate",
        GetByClientId: "/candidate/get-all-candidates-by-client-id",
        GetByJobId: "/candidate/get-all-candidates-by-job-id",
        GetJobRecruiterByJobId: "/candidate/get-job-recruiter-by-job-id",
        GetBillings: "/candidate-billings",
        EditCandidateDetail: "/candidate/edit-basic-info",
        CalculateBilling: "/calculate-billing",
        SortCandidateByStatus:
          "/candidate/get-all-candidates-by-job-and-status-id",
        EditOfferSalary: "/edit-offer-salary",
        GetCandidateLog: "/logs/candidate-activity-by-id",
        SuggestAnEmail: "/generate-email",
        DeleteCandidate: "/delete-conditional-candidate",
        TransferCandidateJob: "/change-candidate-job",
        GetCandidateBilling: "/candidate-all-submitted-bills",
        GetJobsByClientIds: "/client-jobs-for-candidate-job-change",
        GetByClassId: "/class/candidates",
        ChangeCandidateStatusInBulk: "/candidate-future-use",
      },
      RoleAndPermissions: {
        GetAllRole: "/roles/get-all",
        GetAllPermission: "/permissions",
        CreateRole: "/roles",
        UpdateRole: "/roles",
        GetRoleById: "/roles/get-role-by-id",
      },
      CommissionPlans: {
        GetAll: "/commission/get-all-commission-plans",
        CreateCommissionPlan: "/create-commission-plan",
        GetCommissionPlansById: "/commission/get-commission-plans-by-id",
        AddNewCommissionPlan: "/commission/add-new-commission-plan",
        DeleteUpcommingPlan: "/commission/delete-commission-plan",
        GetCommissionApprovals: "/commission-approval",
        EditCommissionApproval: "/edit-commission-approval",
        GetCommissionStatements: "/commission-statement-list",
        GetCommissionStatementDetail: "/commission-statement-detail",
        CreateCommissionStatement: "/commission-statement",
        ExportCommissionStatement: "/export/recruiter-commission",
        DeleteCommissionStatement: "/commission-statement-delete",
      },
      Files: {
        Upload: "/files/upload",
        Remove: "",
        UploadCSV: "/job-source/upload-csv",
        DeleteCV: "/files/delete",
      },

      Export: {
        Job: "/export/job-detail",
        Candidate: "/export/candidates",
      },
      Status: {
        GetAll: "/status/get-all",
        GetSubStatusBreakdown: "/status/sub-status-breakdown",
        UndoStatus: "/undo-candidate-status",
        ChangeStatusDate: "/status/change-candidate-status-dates",
      },
      Dashboard: {
        HistoricalPerformance: {
          HireChart: "/hire-chart",
          SubmittalDecline: "/submittal-decline-chart",
          InterviewDecline: "/interview-decline-chart",
          InterviewNoShowChart: "/interview-no-show-chart",
          PostOfferFallOutChart: "/post-offer-fall-out-chart",
          PostOfferFallOutReasonChart: "/post-offer-fall-out-reason-chart",
          RecruitmentPipeline: "/history-dashboard/historical-performance",
          TTMChart: "/dashboard-ttm-statistics",
        },
        PipelineDashboard: {
          Summary: "/pipline-dashboard/summary-active-candidates",
          RequisitionDetail: "/pipline-dashboard/job-details",
          JobDetailByCandiateStatus:
            "/job-source-details-with-respective-candidates-status",
        },
      },
      UDF: {
        GetFields: "/udf/field-types",
        Create: "/udf/create",
        Update: "/udf/update",
        GetUDF: "/udf/fields-by-client-id",
        GetEditUDFList: "/udf/edit-fields-value",
        EditUDFValues: "/udf/update-udf-field-value",
      },
      Attachment: {
        create: "/create-attachment",
        get: "/attachments",
        preSignedUrl: "/files/getPreSignedUrl",
        deleteS3: "/files/delete",
        delete: "/attachments-delete",
      },
      TTM: {
        syncCandiateTTM: "/sync-ttm",
      },

      Class: {
        addClass: "/class/create-job-class",
        editClass: "/class/create-job-class",
        getClassDetailById: "/class/detail",
        getClientClassList: "/class/all",
        getClassSetting: "/class/get-settings",
        assignCandidate: "/class/candidate-assignment",
        detachCandidate: "/class/candidate-unassigned",
        getClientClassManager: "/class/managers",
        updateClassSetting: "/class/update-class-job-settings",
        cancelClassById: "/class/update-class-job-settings",
        GetStatistics: "/class/states",
        StatusAndSubStatusBreakDown: "/class/status-breakdown",
        UpdateClassStatus: "/class/update-class-status-by-id",
      },
      UploadExcelFile: {
        jobs: "/excel/jobs",
        recruiters: "/excel/recruiters",
        class: "/excel/class",
      },
    },

    Method: {
      GET: "GET",
      POST: "POST",
      PATCH: "PATCH",
      DELETE: "DELETE",
      PUT: "PUT",
    },
    ResponseType: { Blob: "blob", Json: "json" },
    Header: {
      ContentType: "Content-Type",
      ApplicationJson: "application/json",
      Default: (token = "") => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }),
      Authorization: (token = "") => ({
        Authorization: `Bearer ${token}`,
      }),
      Type: {
        Json: "json",
        File: "file",
      },
    },
    StatusCode: {
      NotModified: 304,
      Fobidden: 403,
      Unauthorized: 401,
      InternalServerError: 500,
    },
  },
  Cookie: {
    Key: {
      User: "user",
      EncryptionKey: "source2_logged_in_user",
    },
  },
  UserType: {
    Source2: { id: 1, name: "Source2", value: "SOURCE2" },
    External: { id: 2, name: "External", value: "EXTERNAL" },
  },
  DateFormat: {
    LongDisplay: "MMMM Do YYYY, h:mm:ss a",
    Display: "LL",
    TimeStampLongFormat: "lll",
    TimeStampShortFormat: "ll",
    Response: "YYYY-MM-DD",
    ResponseWithTime: "YYYY-MM-DD HH:mm:ss",
    CommentFormat: "h:mm A - MMMM Do YYYY",
    USFormat: "MM/DD/YYYY",
    DashUSFormat: "MM-DD-YYYY",
    DashUSFormatTimeStamp: "MM-DD-YYYY, LT",
    FileCreatedTime: "MM-DD-YYYYTh-mm-a",
    DashMonthFormat: "YYYY-MM",
    MonthYearFormat: "MM-YYYY",
    LongMonth: "MMMM",
  },
  TimeFomat: { Display: "hh:mm A", Response: "HH:mm" },
  PhoneFormat: {
    USNational: "(###) ###-####",
    USInternational: "+1 (###) ###-####",
  },
  TooltipColor: "#FAAD14",
  CancelJobColor: "#FFCCCB",
  DiscriptionLength: 55,
  Permissions: {
    SuperAdmin: "ADMIN.ADMIN",
    ViewDashboard: "DASHBOARD.READ",
    ViewClients: "CLIENTS.READ",
    ViewInvoices: "INVOICES.READ",
    ViewCommissionApproval: "COMMISSIONAPPROVAL.READ",
    ViewCommissionStatement: "COMMISSIONSTATEMENT.READ",
    ViewBillingApproval: "BILLINGAPPROVAL.READ",
    ViewCandidates: "CANDIDATES.READ",
    ViewBillingClients: "BILLINGCLIENTS.READ",
    ViewBillingJobs: "BILLINGJOBS.READ",
    ViewCommissionPlan: "COMMISSIONPLAN.READ",
    ViewLookupTable: "LOOKUPTABLE.READ",
    ViewRolesAndPermissions: "ROLESANDPERMISSIONS.READ",
    ViewUsers: "USERS.READ",
    ViewJobs: "JOBS.READ",
    ViewLogs: "LOGS.READ",
    ViewClientContacts: "CLIENTCONTACTS.READ",
    ViewScheduledBilling: "SCHEDULEDBILLING.READ",
    CreateJob: "CLIENT.JOB.CREATEJOB",
    UploadJobCSV: "CLIENT.JOB.UPLOADCSV",
    CancelJob: "JOBS.CANCELJOB",
    AddJobCandidate: "JOBS.ADD.CANDIDATE",
    EditCandidate: "CANDIDATE.EDIT",
    ViewCandidateProfile: "CANDIDATE.PROFILE",
    ViewCandidateJobs: "CANDIDATE.JOBDETAIL",
    CreateInvoices: "INVOICE.CREATE",
    CreateOneTimeBilling: "INVOICE.CREATE.ONETIMEBILLING",
    CreateCommissionStatement: "COMMISSIONAPPROVAL.CREATE.COMMISSIONSTATEMENT",
    CreateClientBilling: "CONFIGRATION.BILLING.CLIENT.CREATE",
    EditClientBilling: "CONFIGRATION.BILLING.CLIENT.EDIT",
    CreateJobBilling: "CONFIGRATION.BILLING.JOB.CREATE",
    EditJobBilling: "CONFIGRATION.BILLING.JOB.EDIT",
    CreateCommissionPlan: "CONFIGRATION.COMMISSIONPLAN.CREATE",
    CreateLookup: "CONFIGRATION.LOOKUP.CREATE",
    EditLookup: "CONFIGRATION.LOOKUP.CREATE",
    CreateRole: "CONFIGRATION.ROLESANDPERMISSIONS.CREATE",
    CreateUser: "CONFIGRATION.USER.CREATE",
    EditUser: "CONFIGRATION.USER.EDIT",
    CreateClientContact: "CONFIGRATION.CLIENTCONTACT.CREATE",
    EditClientContact: "CONFIGRATION.CLIENTCONTACT.EDIT",
    DeleteClientContact: "CONFIGRATION.CLIENTCONTACT.DELETE",
    CreateClient: "CLIENTS.CREATE",
    EditClient: "CLIENTS.EDIT",
    ViewBillingRules: "CLIENT.BILLINGRULES",
    EditCandidateStausDates: "CLIENT.JOBS.CANDIDATE.VIEWACTIVITY.UPDATE.DATE",
    ViewClientLookupTables: "CLIENT.LOOKUP.VIEW",
    EditClientLookupTables: "CLIENT.LOOKUP.EDIT",
    ViewHiringManager: "CLIENT.HIRINGMANAGER.VIEW",
    EditHiringManager: "CLIENT.HIRINGMANAGER.EDIT",
    EditClientVBD: "CLIENT.VBD.EDIT",
    ViewClientVBD: "CLIENT.VBD.VIEW",
    DeleteInvoice: "INVOICE.DELETE",
    EditInvoice: "INVOICE.EDIT",
    DeleteCommissionStatement: "COMMISSIONAPPROVAL.DELETE.COMMISSIONSTATEMENT",
    DeleteJob: "JOBS.DELETE",
    DeleteUser: "USERS.DELETE",
    UndoCandidate: "JOBS.CANDIDATE.STATUS.UNDO",
    UDFCreate: "UDF.CREATE",
    UDFEdit: "UDF.EDIT",
    UDFView: "UDF.VIEW",
    AddAttchment: "ATTACHMENT.ADD",
    DeleteAttachment: "ATTACHMENT.DELETE",
    ViewAttachment: "ATTACHMENT.VIEW",
    EditClientBillingRule: "CLIENT.BILLINGRULES.EDIT",
    EditJobBillingRule: "CLIENT.JOB.BILLINGRULES.EDIT",
    AddClassJobCandidate: "CLASS.JOBS.ADD.CANDIDATE",
    CreateClass: "CLASS.CREATE",
    ViewClass: "CLASS.VIEW",
    EditClass: "CLASS.EDIT",
    ClassAssignCandidate: "CLASS.JOBS.ASSIGN.CANDIDATE",
    CandidateProfileMerge: "CANDIDATE.PROFILE.MERGE",
    ManageEngagementsForAssignedCandidates:
      "ENGAGEMENT.MANAGE_FOR_ASSIGNED_CANDIDATES",
    ManageEngagementsForAssignedClients:
      "ENGAGEMENT.MANAGE_FOR_ASSIGNED_CLIENTS",
  },

  Redux: {
    Roles: "roles",
    Recruiters: "recruiters",
    Clients: "clients",
    Details: "details",
    TotalClients: "totalClients",
    BillingType: "billingType",
    ClientSelection: "clientSelection",
    Listing: "listing",
    SelectedClient: "selectedClient",
    SelectedClients: "selectedClients",
    SelectedRecruiters: "selectedRecruiters",
    Source: "source",
    AdSource: "adSources",
    ReportingSchedule: "reportingSchedule",
    Stage: "stage",
    AtsName: "ats",
    IsTestPassed: "isTestPassed",
    CandidateStatus: "candidateStatus",
    JobStatus: "jobStatus",
    JobLocation: "jobLocation",
    JobDepartment: "jobDepartment",
    JobGroup: "jobGroup",
    JobTitle: "jobTitle",
    ContactTypes: "contactTypes",
    BillingClients: "billingClients",
    BillingJobs: "billingJobs",
    Lookup: "lookup",
    CreateClientData: "createClientData",
    ClientDetails: "clientDetails",
    BillingUnit: "billingUnit",
    ClientLevelBilligRules: "clientLevelBilligRules",
    UserDesignation: "userDesignation",
    Ownership: "ownership",
    BillingTypes: "billingTypes",
    ShouldUpdate: "shouldUpdate",
    Currencies: "currencies",
    HomeRoute: "homeRoute",
    ShouldReinitialize: "shouldReinitialize",
    DashboardTab: "dashboardActiveTab",
    DashboardStatusModalVisible: "statusModalVisible",
    ClientUdf: "clientUdf",
    CandidateUdf: "candidateUdf",
    JobSourceUdf: "jobSourceUdf",
    ClassStatus: "classStatus",
  },
  LookupTabKeys: {
    Source: "source",
    AdSource: "ad-source",
    ReportingSchedule: "reporting-schedule",
    Stage: "stage",
    ATSNames: "ats-names",
    CandidateStatus: "candidate-status",
    JobStatus: "job-status",
    JobLocations: "job-locations",
    JobDepartments: "job-departments",
    JobGroups: "job-groups",
    JobTitle: "job-title",
    ContactTypes: "contact-types",
    UserDesignation: "user-designations",
    Ownership: "ownership",
    BillingTypes: "billing-types",
    ClassStatus: "class-status",
  },

  LookupTableNames: {
    Source: "sources",
    AdSource: "ad_sources",
    ReportingSchedule: "reporting_schedule_look_ups",
    Stage: "stage_look_ups",
    ATSNames: "ats_look_ups",
    CandidateStatus: "candidate_statuses",
    JobStatus: "job_statuses",
    JobLocations: "job_locations",
    JobDepartments: "job_departments",
    JobGroups: "job_groups",
    JobTitle: "job_titles",
    ContactTypes: "contact_types",
    UserDesignation: "user_designation_lookups",
    Ownership: "ownership_lookup",
    BillingTypes: "one_time_payment_billing_types",
    ClassStatus: "class_statuses",
  },
  DetailsKeys: {
    Overview: "overview",
    Lookup: "lookup",
    HiringManager: "hiring-manager",
    Jobs: "jobs",
    JobInvoices: "job-invoices",
    ClientInvoices: "client-invoices",
    JobBillingRules: "job-billing-rules",
    ClientBillingRules: "client-billing-rules",
    UserDefineds: "user-defineds",
    Attachment: "attachments",
    Class: "class",
  },
  JobBillingRuleTabKeys: {
    Tiers: "tiers",
    VBD: "vbd",
  },
  BillingTypes: {
    OneTime: "ONE_TIME",
    Recursive: "RECURSIVE",
    TimeBased: "TIME_BASED",
    Retention: "RETENTION",
  },
  CandidateHireType: {
    AppliedHIRE: "appliedHire",
    SourceHired: "sourceHired",
    Referral: "referral",
    Cancellations: "cancellations",
    HiringEvent: "hiringEvent",
    ReHired: "reHired",
  },
  hireType: [
    { value: "applied_hire", label: "Applied Hire" },
    { value: "source_hired", label: "Sourced Hire" },
    { value: "referral", label: "Referral" },
    { value: "hiring_event", label: "Hiring Event" },
    { value: "re_hired", label: "Rehire" },
  ],
  UploadHireType: [
    { value: "applied_hire", label: "Applied Hire" },
    { value: "source_hired", label: "Sourced Hire" },
    { value: "referral", label: "Referral" },
    { value: "hiring_event", label: "Hiring Event" },
    { value: "re_hired", label: "Rehire" },
    { value: "re_hire_referral", label: "Rehire Referral" },
    { value: "re_hire_applied", label: "Rehire Applied" },
    { value: "re_hire_sourced", label: "Rehire Sourced" },
  ],

  RecurringType: {
    Week: "WEEK",
    Month: "MONTH",
    Day: "DAY",
  },
  CommissionThresholdType: {
    Amount: "AMOUNT",
    TotalHire: "TOTAL_HIRE",
  },
  Format: {
    Dollar: "AMOUNT",
    Percentage: "PERCENT",
  },
  CurrencyType: {
    Dollar: "$",
    CAD: "C$",
  },
  AgGridTable: {
    TrailKey:
      "[TRIAL]_this_AG_Grid_Enterprise_key_( AG-040680 )_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_purchasing_a_production_key_please_contact_( info@ag-grid.com )___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_( 30 June 2023 )____[v2]_MTY4ODA3OTYwMDAwMA==5266e2b19455d0b5c7c8c52bf6367755",
    DefaultProps: {
      pagination: true,
      suppressMultiSort: true,
      paginationPageSize: 25,
      loadingCellRenderer: () => "",
    },
    DefaultColDef: {
      flex: 1,
      minWidth: 150,
      resizable: true,
      cellStyle: { border: "none", textAlign: "center" },
      headerClass: "text-center",
      suppressMovable: false,
      hide: false,
      pinned: null,
    },
    Keys: {
      Candidates: "Candidates",
      Jobs: "Jobs",
      UserVisibleColumn: "User_Visible_Columns",
      GridLayoutOrder: "Grid_Layout_Order",
      LookupVisibleColumn: "Lookup_Visible_Columns",
      ClientVisibleColumn: "Clients_Visible_Columns",
      GlobalJobsVisibleColumn: "Global_Jobs_Visible_Columns",
      ClientJobsVisibleColumn: "Client_Jobs_Visible_Columns",
      BillingJobsVisibleColumn: "Billing_Jobs_Visible_Columns",
      BillingClientsVisibleColumn: "Billing_Clients_Visible_Columns",
      ClientContactsVisibleColumn: "Client_Contacts_Visible_Columns",
      CommissionPlansVisibleColumn: "Commission_Plans_Visible_Columns",
      RolePermissionsVisibleColumn: "Roles_Permissions_Visible_Columns",
      CommissionApprovalsVisibleColumn: "Commission_Approvals_Visible_Columns",
      CommissionStatementVisibleColumn: "Commission_Statement_Visible_Columns",
      SubmittedClientBillingsVisibleColumn:
        "Submitted_Client_Billings_Visible_Columns",
      SubmittedJobBillingsVisibleColumn:
        "Submitted_Job_Billings_Visible_Columns",

      HiringManagerVisibleColumn: "Hiring_Manager_Visible_Columns",
      UserDefinedsVisibleColumn: "User_Defineds_Visible_Columns",
      AttachmentVisibleColumn: "Attachment_Visible_Columns",
      JobDetailCandidateColumn: "Job_Detail_Candidate_Columns",
      ClientClassVisibleColumn: "Client_Class_Visible_Columns",
      ThisWeekEngagementsVisibleColumn: "This_Week_Engagements_VisibleColumns",
      PastDueEngagementsVisibleColumn: "Past_Due_Engagements_VisibleColumns",
      FutureEngagementsVisibleColumn: "Future_Engagements_VisibleColumns",
    },
  },
  DashboardKeys: {
    VisibleCharts: "Visible_Charts",
  },
  AttachmentType: {
    Candidate: "CANDIDATE",
    Client: "CLIENT",
  },
  ReactTrelloEvents: {
    AddCard: "ADD_CARD",
    UpdateCard: "UPDATE_CARD",
    RemoveCard: "REMOVE_CARD",
    MoveCard: "MOVE_CARD",
    UpdateLanes: "UPDATE_LANES",
  },
  S3Folders: {
    CV: "candidate_cv",
    ProfilePicture: "profile_picture",
    ClientAttachments: "client_",
    CandidateAttachments: "candidate_",
  },
  CandidateStatus: {
    Prospects: "Prospect",
    Candidates: "Candidates",
    S2Interview: "S2 Interview",
    Submitted: "Submitted",
    ClientInterview: "Client Interview",
    Offer: "Offer",
    Withdrew: "Withdrew",
    Declined: "Declined",
    Hired: "Hired",
    Terminated: "Terminated",
  },
  Invoice: {
    Type: {
      Job: "JOB",
      Client: "CLIENT",
      Commission: "COMMISSION",
    },
    Status: {
      Draft: "Draft",
      "Billed in QuickBooks": "Billed_In_QuickBooks",
      Paid: "Paid",
      "Not Paid": "Not_Paid",
    },
  },
  Users: { Type: { Source2: "SOURCE2", External: "EXTERNAL" } },
  CandidateTypes: {
    applied_hire: "Applied Hire",
    source_hired: "Sourced Hire",
    referral: "Referral",
    hiring_event: "Hiring Event",
    re_hired: "Rehire",
    re_hire_referral: "Rehire Referral",
    re_hire_applied: "Rehire Applied",
    re_hire_sourced: "Rehire Sourced",
  },
  CancelJobReasons: [
    { key: 1, value: "Position Filled Internally", input: false },
    { key: 2, value: "Role Not Approved/Budgeted", input: false },
    { key: 3, value: "Seasonality/Hiring Freeze", input: false },
    { key: 4, value: "Req Moved to Client/Internally", input: false },
    { key: 5, value: "Refreshing Position", input: false },
    { key: 6, value: "Job Requirements Reassessed", input: false },
    { key: 7, value: "Changing of Job Title", input: false },
    { key: 8, value: "Other", input: true },
  ],
  JobStatus: {
    open: "open-flag",
    closed: "closed-flag",
    "on hold": "onhold-flag",
    custom: "blue-flag",
  },

  Status: {
    Prospect: {
      id: 1,
      type: "active",
      name: "Prospect",
      subStatus: {
        Sourced: { id: 1, name: "Sourced" },
        FirstContacted: { id: 2, name: "First Contacted" },
        SecondContacted: { id: 3, name: "Second Contacted" },
        ThirdContacted: { id: 4, name: "Third Contacted" },
        NotInterested: { id: 43, name: "Not Interested" },
        Interested: { id: 44, name: "Interested" },
      },
    },
    Candidates: {
      id: 2,
      type: "active",
      name: "Candidates",
      subStatus: {
        NeedsReview: { id: 5, name: "Needs Review" },
        ReviewComplete: { id: 6, name: "Review Complete" },
      },
    },
    S2Interview: {
      id: 3,
      type: "active",
      name: "S2 Interview",
      subStatus: {
        Contacted: { id: 7, name: "Contacted" },
        Scheduled: { id: 8, name: "Scheduled" },
        Complete: { id: 9, name: "Complete" },
      },
    },
    Submitted: {
      id: 4,
      type: "active",
      name: "Submitted",
      subStatus: {
        AwaitingFeedback: { id: 10, name: "Awaiting Feedback" },
        SchedulingInterview: { id: 11, name: "Scheduling Interview" },
      },
    },
    ClientInterview: {
      id: 5,
      type: "active",
      name: "Client Interview",
      subStatus: {
        AwaitingFeedback: { id: 12, name: "Awaiting Feedback" },
        SecondInterview: { id: 46, name: "Second Interview" },
        ThirdInterview: { id: 45, name: "Third Interview" },
      },
    },
    Offer: {
      id: 6,
      type: "active",
      name: "Offer",
      ExtentedId: 13,
      AcceptedId: 14,
      subStatus: {
        Approval: { id: 47, name: "Approval" },
        Extended: { id: 13, name: "Extended" },
        Accepted: { id: 14, name: "Accepted" },
      },
    },
    Withdrew: {
      id: 7,
      type: "final",
      name: "Withdrew",
      subStatus: {
        Benefits: {
          id: 15,
          name: "Benefits",
        },
        Culture: {
          id: 16,
          name: "Culture",
        },
        DidNotDisclose: {
          id: 17,
          name: "Did Not Disclose",
        },
        Location: {
          id: 18,
          name: "Location",
        },
        NoLongerInterested: {
          id: 19,
          name: "No Longer Interested",
        },
        Pay: {
          id: 20,
          name: "Pay",
        },
        Requirements: {
          id: 21,
          name: "Requirements",
        },
        Schedule: {
          id: 22,
          name: "Schedule",
        },
        Unresponsive: {
          id: 33,
          name: "Unresponsive",
        },
      },
    },
    Declined: {
      id: 8,
      type: "final",
      name: "Declined",
      subStatus: {
        Communication: {
          id: 23,
          name: "Communication",
        },
        CulturalFit: {
          id: 24,
          name: "Cultural Fit",
        },
        Experience: {
          id: 25,
          name: "Experience",
        },
        Location: {
          id: 26,
          name: "Location",
        },
        Pay: {
          id: 27,
          name: "Pay",
        },
        Professionalism: {
          id: 28,
          name: "Professionalism",
        },
        Requirements: {
          id: 29,
          name: "Requirements",
        },
        Schedule: {
          id: 30,
          name: "Schedule",
        },
        NoShowToInterview: {
          id: 31,
          name: "No Show to Interview",
        },
        RequisitionClosed: {
          id: 32,
          name: "Requisition Closed",
        },
        Assessment: {
          id: 34,
          name: "Assessment",
        },
        Background: {
          id: 35,
          name: "Background",
        },
        DrugScreen: {
          id: 36,
          name: "Drug Screen",
        },
        MVR: {
          id: 37,
          name: "MVR",
        },
        Physical: {
          id: 38,
          name: "Physical",
        },
      },
    },
    Hired: {
      id: 9,
      type: "final",
      name: "Hired",
      subStatus: {
        StartedWork: {
          id: 39,
          name: "Started Work",
        },
      },
    },
    Terminated: {
      id: 10,
      type: "final",
      name: "Terminated",
      subStatus: {
        NoShowToDay1: {
          id: 40,
          name: "No Show to Day 1",
        },
        WithinGuarantee: {
          id: 41,
          name: "Within Guarantee",
        },
        WithinRetentionPeriod: {
          id: 42,
          name: "Within Retention Period",
        },
      },
    },
  },
  StatusDefinations: {
    ActiveCandidate:
      "Any candidate who is currently active in the recruitment process (submitted, interviewing, offer stage, pending start, etc.)",
    Prospect:
      "Any candidate who has been sent a solicitation and we are awaiting a response.",
    UnderReview:
      "Any candidate who has applied and their qualifications are currently being reviewed.",
    S2Interview:
      "Any candidate who is scheduled to conduct a Source2 phone interview.",
    Submittal:
      "Any candidate who has been submitted to the client hiring manager with feedback pending.",
    ClientInterview:
      "Any candidate who has a scheduled interview with the hiring manager with feedback pending.",
    OfferExtended:
      "Any candidate who has been extended an offer and we are awaiting a response.",
    OfferAccepted:
      "Any candidate who has accepted an offer but has not started work.",
    Hired: "Any candidate who has started work.",
  },
  EditComponentType: {
    CheckBox: "CheckBox",
    InputNumber: "InputNumber",
    InputText: "Input",
    DatePicker: "DatePicker",
    TimePicker: "TimePicker",
  },
  StatusTagColors: {
    Prospect: "#D9D9D9",
    Candidates: "#F6A2E6",
    Submitted: "#BDD7EE",
    Offer: "#99FF99",
    Withdrew: "#F9B367",
    Declined: "#FF9999",
    Hired: "#00CC66",
    Terminated: "#FF9999",
    "S2 Interview": "#33CCFF",
    "Client Interview": "#CC99FF",
    "No Show to Day 1": "#FF9999",
    "Within Guarantee": "#FFB66D",
    "Within Retention Period": "#FFB66D",
  },
  EngagementStatus: {
    All: {
      ReadyForMilestoneCheckIn: {
        label: "Ready For Milestone Check-In",
        value: "ready_for_milestone_check_in",
        userAccessible: true,
      },
      OutreachFirstMade: {
        label: "Outreach 1 Made",
        value: "outreach_1_made",
        userAccessible: true,
      },
      OutreachSecondMade: {
        label: "Outreach 2 Made",
        value: "outreach_2_made",
        userAccessible: true,
      },
      Completed: {
        label: "Completed",
        value: "completed",
        userAccessible: true,
      },
      CompletedAtRisk: {
        label: "Completed At Risk",
        value: "completed_at_risk",
        userAccessible: true,
      },
      NotResponsive: {
        label: "Not Responsive",
        value: "not_responsive",
        userAccessible: true,
      },
      RecruiterMissed: {
        label: "Recruiter Missed",
        value: "recruiter_missed",
        userAccessible: false,
      },
    },

    byKey: function (key) {
      return Object.values(this.All).find((k) => k.value === key);
    },

    closed: function () {
      return [
        this.All.Completed,
        this.All.CompletedAtRisk,
        this.All.NotResponsive,
        this.All.RecruiterMissed,
      ];
    },
  },

  UDF: {
    Entities: {
      Client: { id: 1, key: "client_id" },
      JobSource: { id: 2, key: "job_source_id" },
      Candidate: { id: 3, key: "candidate_job_entry_id" },
    },
    Fields: {
      1: {
        displayName: "Checkbox",
        type: "Checkbox",
        props: {},
        component: UDFOptions,
        displayComponent: UDFCheckbox,
        isDate: false,
        isTime: false,
        isMultiValue: true,
        isOption: true,
      },
      2: {
        displayName: "Radio",
        type: "Radio",
        props: {},
        component: UDFOptions,
        displayComponent: UDFRadio,
        isDate: false,
        isTime: false,
        isMultiValue: false,
        isOption: true,
      },
      3: {
        displayName: "Date",
        type: "DatePicker",
        props: {},
        component: null,
        displayComponent: UDFDatePicker,
        isDate: true,
        isTime: false,
        isMultiValue: false,
        isOption: false,
      },
      4: {
        displayName: "Time",
        type: "TimePicker",
        props: {},
        component: null,
        displayComponent: UDFTimePicker,
        isDateTime: false,
        isTime: true,
        isMultiValue: false,
        isOption: false,
      },
      5: {
        displayName: "Text",
        type: "Input",
        props: {},
        component: null,
        displayComponent: UDFInput,
        isDate: false,
        isTime: false,
        isMultiValue: false,
        isOption: false,
      },
      6: {
        displayName: "Text Area",
        type: "TextArea",
        props: {},
        component: null,
        displayComponent: UDFTextArea,
        isDate: false,
        isTime: false,
        isMultiValue: false,
        isOption: false,
      },
      7: {
        displayName: "Number",
        type: "InputNumber",
        props: { isNumber: true },
        component: null,
        displayComponent: UDFInput,
        isDate: false,
        isTime: false,
        isMultiValue: false,
        isOption: false,
      },
      8: {
        displayName: "Select",
        type: "Select",
        props: { isNumber: true },
        component: UDFOptions,
        displayComponent: UDFSelect,
        isDate: false,
        isTime: false,
        isMultiValue: false,
        isOption: true,
      },
      9: {
        displayName: "Multi Select",
        type: "Select",
        props: { mode: "multiple" },
        component: UDFOptions,
        displayComponent: UDFSelect,
        isDate: false,
        isTime: false,
        isMultiValue: true,
        isOption: true,
      },
    },
  },
  Limits: {
    Engagement: {
      export: 10000,
    },
  },
};

export default K;
