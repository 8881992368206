import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  PageHeader,
  Popconfirm,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import "assets/fonticons/style.css";
import PdfLogo from "assets/images/pdflogo.png";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommissionPlan from "redux/models/commissionPlan";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import {
  checkNullPlaceHolder,
  isPermissionPresent,
  removeUnderScore,
} from "utilities/generalUtility";
import { displayDollar, displayHire } from "utilities/tableUtility";
import styles from "../invoices/clientInvoices.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectUser } from "redux/slices/userSlice";
import { useSelector } from "react-redux";
import Tooltip from "../../../common/components/Tooltip/Tooltip";

const { Title, Text, Paragraph } = Typography;
const tagColors = {
  SUCCESS: "success",
  PENDING: "blue",
};

export default function CommissionStatementDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [pageData, setPageData] = useState({
    details: null,
  });
  const { details } = pageData;
  const userSlice = useSelector(selectUser);
  console.log(details);
  const columns = [
    {
      title: "SR #",
      align: "center",
      render: (_, __, i) => i + 1,
    },
    {
      title: "Candidate Name",
      align: "center",
      dataIndex: "submittedJobBill",
      render: (obj) => checkNullPlaceHolder(obj?.candidateName),
    },
    {
      title: "Client Name",
      align: "center",
      dataIndex: "submittedJobBill",
      render: (obj) => checkNullPlaceHolder(obj?.client?.name),
    },
    {
      title: "Candidate Type",
      align: "center",
      dataIndex: "submittedJobBill",
      render: (obj) =>
        K.CandidateTypes[obj?.candidateJobEntry?.hireType] ||
        "Cancellation Fee",
    },
    {
      title: "Billing type",
      dataIndex: "submittedJobBill",
      align: "center",
      render: (obj) => {
        const text = removeUnderScore(obj?.billingTypeName);
        return <Tooltip text={text}>{text}</Tooltip>;
      },
    },
    {
      title: "Candidate Start Date",
      dataIndex: "submittedJobBill",
      align: "center",
      render: (text) =>
        convertIntoDashUSFormat(text?.candidateJobEntry?.startDate, false),
    },
    {
      title: "Billed Amount",
      dataIndex: "thresholdMaker",
      align: "center",
      render: (text, record) => displayDollar(text),
    },
    {
      title: "Commission Earned",
      dataIndex: "duplicateAmount",
      align: "center",
      render: (text) => displayDollar(text),
    },
  ];
  const deleteCommission = async () => {
    try {
      await CommissionPlan.deleteCommissionStatment(id);
      history.goBack();
      message.success("Commission statment deleted successfully");
    } catch (err) {
      setPageData((prev) => ({ ...prev, isModalVisible: false }));
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await CommissionPlan.getCommissionStatementDetail(id);
        setPageData((prev) => ({
          ...prev,
          details: res,
        }));
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  return (
    <>
      <div className="pdf-wrap">
        <div className="pdf-header">
          <div className="pdf-heading">
            <Title level={5}>Source 2</Title>
          </div>
          <div className="pdf-header-img">
            <img src={PdfLogo} alt="" />
          </div>
        </div>
        <div className="report-list-wrap">
          <Paragraph className="report-para">
            941 W Morse Boulevard, Suite 100<br></br>Winter Park, FL 32789 US
            <br></br>
            accounting@source2.com<br></br> www.source2.com
          </Paragraph>
          <div className="d-flex justify-content-between">
            <Title level={2}>Commission Statement</Title>
            <Title style={{ color: "black" }} level={2}>
              {`Payout: ${
                details?.status === "NOT_ELIGIBLE"
                  ? displayDollar(0)
                  : displayDollar(details?.amount ?? 0)
              }`}
            </Title>
          </div>
          <Title level={5}>BILL TO</Title>
          <ul className="report-list-ul">
            <li>
              <b>Recruiter ID:</b>
              <Text>{details?.recruiter.id}</Text>
            </li>
            <li>
              <b>Total Amount Billed:</b>
              <Text>
                {displayDollar(
                  details?.commissionApprovals.reduce(
                    (accumulator, currentItem) =>
                      accumulator + currentItem.thresholdMaker,
                    0,
                  ),
                )}
              </Text>
            </li>
          </ul>
          <ul className="report-list-ul">
            <li>
              <b>Recruiter Name:</b>
              <Text>{details?.recruiter.name}</Text>
            </li>
            <li>
              <b>Threshold: </b>
              <Text>
                {details?.thresholdType === K.CommissionThresholdType.Amount
                  ? displayDollar(details?.billingThreshold)
                  : displayHire(details?.billingThreshold)}
              </Text>
            </li>
          </ul>

          <ul className="report-list-ul">
            <li>
              <b>Status:</b>
              <Text>
                <Tag color={tagColors[details?.status]}>
                  {removeUnderScore(details?.status)}
                </Tag>
              </Text>
            </li>
            <li>
              <b>Payout Date: </b>
              <Text>
                {convertIntoDashUSFormat(details?.deliveryDate, false)}
              </Text>
            </li>
          </ul>
        </div>
        <div className="header-divider"></div>
      </div>
      <div className={styles.wrapPageHeader}>
        <PageHeader
          className={LayoutCss.appPageHeader}
          ghost={false}
          onBack={() => window.history.back()}
          title="Statement Details"
          extra={
            isPermissionPresent(
              K.Permissions.DeleteCommissionStatement,
              userSlice.roles,
            ) && (
              <div className={styles.invoiceModal}>
                <Popconfirm
                  placement="bottom"
                  title={
                    "Are you sure you want to delete this commission statment?"
                  }
                  onConfirm={deleteCommission}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    danger={true}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            )
          }
        />
      </div>
      <div className={styles.topInfoCard}>
        <Card className={styles.generateInvoiceCard}>
          <div className={styles.topCardDetail}>
            <div className={styles.cardBox}>
              <ul>
                <li>
                  Payout Date:
                  <span>
                    {convertIntoDashUSFormat(details?.deliveryDate, false)}
                  </span>
                </li>

                <li>
                  Threshold:
                  <span>
                    {details?.thresholdType === K.CommissionThresholdType.Amount
                      ? displayDollar(details?.billingThreshold)
                      : displayHire(details?.billingThreshold)}
                  </span>
                </li>
                <li>
                  Total Amount Billed:
                  <span>
                    {displayDollar(
                      details?.commissionApprovals.reduce(
                        (accumulator, currentItem) =>
                          accumulator + currentItem.thresholdMaker,
                        0,
                      ),
                    )}
                  </span>
                </li>
                <li>
                  <span className={styles.cardIdDate}>Status:</span>
                  <span className={styles.cardIdDate}>
                    <Tag color={tagColors[details?.status]}>
                      {details?.status
                        ? removeUnderScore(details.status)
                        : K.NullPlaceholder}
                    </Tag>
                  </span>
                </li>
              </ul>
            </div>

            <div className={styles.cardRightSide}>
              <ul>
                <li className={`${styles.cardDetails} ${styles.clientBalance}`}>
                  Recruiter:
                </li>
                <li>{details?.recruiter.name}</li>
                <li className={styles.cardDetails}>Payout:</li>
                <li
                  className={`${styles.cardDetails} ${styles.invoicesDetails}`}
                >
                  {details?.status === "NOT_ELIGIBLE"
                    ? displayDollar(0)
                    : displayDollar(details?.amount ?? 0)}
                </li>
                <div className={styles.invoicesPrint}>
                  <Button
                    type="link"
                    className={styles.cardDetails}
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Print
                  </Button>
                </div>
              </ul>
            </div>
          </div>
        </Card>
      </div>

      <Card className={styles.bottomInfoCard}>
        <div className={styles.bottomCardList}>
          <div className={styles.bottomCardIcons}>
            <ul>
              <li>
                <div className={styles.invoiceIcon}>
                  <UserOutlined className={styles.cardIcon} />
                </div>
                <div className={styles.invoiceIconDetail}>
                  <p className={styles.listName}>Recruiter</p>
                  <Title level={5} className={styles.listDes}>
                    {details?.recruiter.name}
                  </Title>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={details?.commissionApprovals ?? []}
          size="small"
          pagination={false}
          className="invoiceTable"
          scroll={{ x: true }}
        />
      </Card>
    </>
  );
}
